import { TremorCard } from 'ui';
import { useUnaccountedTransactions } from '../../hooks/http/useUnaccountedTransactions';
import { getDisplayedTransactions } from '../Sources/utils';
import { useContext } from 'react';
import { SidebarGlobalContext } from '../../context';
import Table from 'src/components/tables/tanstack-table/Table';
import { Title } from '@tremor/react';
import { overviewTransactionsColumns } from '../tables/columns/OverviewTransactionsColumns';

export const UnaccountedTransactionsWidget = () => {
  const { data: unaccountedTransactions, isLoading } = useUnaccountedTransactions();

  const displayedTransactions = getDisplayedTransactions(unaccountedTransactions || []);

  const { openSidebar } = useContext(SidebarGlobalContext);
  return (
    <TremorCard className='w-full'>
      <Title className='mb-4'>Top transactions needing accounting</Title>
      <div className='overflow-x-auto'>
        <Table
          onRowClick={(row) => {
            openSidebar('transactions', { id: row.original._id, primaryOrSecondary: 'primary' });
          }}
          enableRowSelection={false}
          isLoading={isLoading}
          columns={overviewTransactionsColumns}
          data={displayedTransactions}
          hideFetchingNextPage={true}
        />
      </div>
    </TremorCard>
  );
};
