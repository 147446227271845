import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { LOADER_ICON_SIZE, LoaderIcon } from 'ui';

export const FetchNextPage = ({ fetchNextPage, hasNextPage = true, isFetchingNextPage, dataLength, shouldFetch, containerRef }: any) => {
  const { ref, inView } = useInView({
    root: containerRef?.current
  });
  const [hasFetched, setHasFetched] = useState(false);
  const [dataLengthLoad, setDataLengthLoad] = useState(dataLength);

  useEffect(() => {
    if (inView && shouldFetch && hasNextPage && !isFetchingNextPage && !hasFetched) {
        fetchNextPage();
        setHasFetched(true);
    } else if (inView && (dataLength > dataLengthLoad)) {
      setHasFetched(true);
    }

  }, [inView, hasFetched]);

  useEffect(() => {
    if (dataLength > dataLengthLoad) {
      setDataLengthLoad(dataLength);
      setTimeout(() => {
        setHasFetched(false);
      }, 250)
    }
  }, [dataLength]);

  if (dataLength === 0) return null;

  return (
    <>
      {hasNextPage && <div className={`w-full h-[51px] bg-white`} ref={ref} />}
      {isFetchingNextPage && (
        <div className='static h-[calc(100vh-298px)] md:fixed right-0 bottom-0 left-0 md:z-[55] md:rounded-b-[12px] md:top-[unset] w-full my-8 flex justify-center items-center bg-[rgba(255,255,255,.65)] overflow-hidden'>
          <LoaderIcon size={LOADER_ICON_SIZE.LARGE} />
        </div>
      )}
    </>
  );
};
